import React from 'react'
import Bubble from '@/components/atoms/Bubble'
import { withLayout } from '@/components/template/Layout'
import styled from 'styled-components'
import theme from '@/theme'
import InfoPageTitle from '@/components/atoms/InfoPageTitle'

const Label = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-right: 12px;
`

const QuestionWrapper = styled.div`
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    color: ${theme.colors.black};
`

const Question = (props: { children: React.ReactNode }) => (
    <QuestionWrapper>
        <Label>Q.</Label>
        <span>{props.children}</span>
    </QuestionWrapper>
)

const AnswerWrapper = styled.div`
    display: flex;
    color: ${theme.colors.black};

    p:first-child {
        margin-top: 0;
    }
`

const Answer = (props: { children: React.ReactNode }) => (
    <AnswerWrapper>
        <Label>A.</Label>
        <div>{props.children}</div>
    </AnswerWrapper>
)

const Footer = styled.div`
    color: ${theme.colors.black};
    font-size: 16px;
    margin: 30px;

    > h3 {
        margin: 5px;
    }
`

const Questions = () => {
    return (
        <>
            <InfoPageTitle>よくあるご質問</InfoPageTitle>
            <Bubble>
                <Question>サプライチェーン排出量について教えてください。</Question>
                <Answer>
                    <p>
                        事業者自らの排出だけでなく、原材料調達・製造・物流・販売・廃棄など、一連の流れ全体から発生する温室効果ガス排出量のことです。
                        <br />
                        サプライチェーン排出量は以下の3つのScopeから構成されています。
                        <br />
                    </p>
                    <p>
                        Scope1：事業者自らによる温室効果ガスの直接排出
                        <br />
                        Scope2：他者から供給された電気、熱・蒸気の使用に伴う間接排出
                        <br />
                        Scope3： Scope1、Scope2以外の間接排出
                        <br />
                    </p>
                    <p>
                        さらに、GHGプロトコルのScope3基準では、Scope3を15カテゴリに分類します。
                        <br />
                    </p>
                </Answer>
            </Bubble>
            {/* ---------------------------------------------------------------------------------------------------------------------- */}
            <Bubble>
                <Question>GHGプロトコルについて教えてください。</Question>
                <Answer>
                    <p>
                        GHGプロトコルは、WRI（世界資源研究所）とWBCSD（持続可能な開発のための世界経済人会議）が中心となって作成したGHG（温室効果ガス）
                        排出量の算定と報告の国際基準です。
                    </p>
                    <p>
                        Scope3基準は、GHGプロトコルが発行した組織のサプライチェーン全体の排出量の算定基準で、日本の環境省と経済産業省は、この基準に整合した
                        「サプライチェーンを通じた温室効果ガス排出量算定に関する基本ガイドライン」を作成しています。
                    </p>
                </Answer>
            </Bubble>
            {/* ---------------------------------------------------------------------------------------------------------------------- */}
            <Bubble>
                <Question>温室効果ガス排出量の算定・報告・公表制度について教えてください。</Question>
                <Answer>
                    <p>
                        改正された地球温暖化対策の推進に関する法律（温対法）に基づき、平成18年4月1日から、温室効果ガスを多量に排出する者（特定排出者）に、
                        自らの温室効果ガスの排出量を算定し、国に報告することが義務付けられました。また、国は報告された情報を集計し、公表することとされています。
                    </p>
                </Answer>
            </Bubble>
            {/* ---------------------------------------------------------------------------------------------------------------------- */}
            <Bubble>
                <Question>サプライチェーン排出量はどのように算定しますか？</Question>
                <Answer>
                    <p>
                        サプライチェーン排出量の算定は、以下のようにScope1,2排出量とScope3排出量を合計して算定します。
                        <br />
                        <br />
                        『サプライチェーン排出量 ＝ Scope1排出量 ＋ Scope2排出量 ＋ Scope3排出量』
                        <br />
                        <br />
                        排出量算定の基本式は以下の通りです。
                        <br />
                        <br />
                        『排出量 ＝ 活動量 × 排出量原単位』
                        <br />
                    </p>
                </Answer>
            </Bubble>
            {/* ---------------------------------------------------------------------------------------------------------------------- */}
            <Bubble>
                <Question>活動量、排出原単位について教えてください。</Question>
                <Answer>
                    <p>
                        活動量とは、事業者の活動の規模に関する量のことです。例えば電気の使用量、貨物の輸送量、廃棄物の処理量、各種取引金額などが該当します。
                        社内の各種データや、文献データ、業界平均データ、製品の設計値等から収集します。
                    </p>
                    <p>
                        排出原単位とは、活動量あたりのCO2排出量のことです。例えば、電気1kWh使用あたりのCO2排出量、貨物の輸送量1トンキロあたりのCO2排出量、
                        廃棄物の焼却1tあたりのCO2排出量などが該当します。基本的には既存のデータベースから選択して使用しますが、排出量を直接計測する方法や、
                        取引先から排出量の算定結果の提供を受ける方法もあります。（当サービスでは、既存のデータベースから選択して使用する方法を採用しています）
                    </p>
                </Answer>
            </Bubble>
            <Footer>
                <h3>詳しい内容は、以下のホームページをご覧ください。</h3>
                <ul>
                    <li>
                        <a href="https://www.env.go.jp/earth/ondanka/supply_chain/gvc/index.html">
                            グリーン・バリューチェーンプラットフォーム
                        </a>
                        (環境省/経済産業省)
                    </li>
                    <li>
                        <a href="https://ghg-santeikohyo.env.go.jp">温室効果ガス排出量 算定・報告・公表制度</a>
                        (環境省)
                    </li>
                </ul>
            </Footer>
        </>
    )
}

export default withLayout(Questions)
